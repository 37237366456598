// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT_AUTH = '/auth';
const ROOT_DASHBOARD = '/dashboard';
const ROOT_CUSTOMERS = '/customers';
const ROOT_SECURITY = '/security';
const ROOT_SETTING = '/settings';
const ROOT_ORDERS = '/orders';
const ROOT_PROFILE = '/profile';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOT_AUTH,
  login: path(ROOT_AUTH, '/login'),
  register: path(ROOT_AUTH, '/register'),
  loginUnprotected: path(ROOT_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOT_AUTH, '/register-unprotected'),
  verify: path(ROOT_AUTH, '/verify'),
  resetPassword: path(ROOT_AUTH, '/reset-password'),
  newpassword: (token, userId) => path(ROOT_AUTH, `/new-password/${token}/${userId}`),
  authenticate: path(ROOT_AUTH, '/authenticate'),
  // newPassword: path(ROOT_AUTH, '/new-password/${id}/asset/${userId}/edit'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  invalidErrorPage:'/InvalidErrorPage',
  expiredErrorPage:'/ExpiredErrorPage',
};

// --------------------- Dashboard ----------------------
export const PATH_DASHBOARD = {
  root: ROOT_DASHBOARD,
  permissionDenied: path(ROOT_DASHBOARD, '/permission-denied'),
  blank: path(ROOT_AUTH, '/login')
};

export const PATH_PROFILE = {
  view: (id) => path(ROOT_PROFILE, `/${id}/`),
  edit: (id) => path(ROOT_PROFILE, `/${id}/edit`),
  sites: (id) => path(ROOT_PROFILE, `/${id}/sites`),
};


// --------------------- Customer -----------------------
export const PATH_CUSTOMERS = {
  root:ROOT_CUSTOMERS,
  new: path(ROOT_CUSTOMERS, '/new'),
  view: (id) => path(ROOT_CUSTOMERS, `/${id}/view`),
  edit: (id) => path(ROOT_CUSTOMERS, `/${id}/edit`),
  sites: (id) => path(ROOT_CUSTOMERS, `/${id}/sites`), 
};

export const PATH_ORDERS = {
  root:ROOT_ORDERS,
  new:path(ROOT_ORDERS,`/new`),
  view: (id) => path(ROOT_ORDERS,`/${id}/view`),
  edit: (id) => path(ROOT_ORDERS,`/${id}/edit`)
};

export const PATH_SETTING = {
  permissionDenied: path(ROOT_SETTING, '/permission-denied'),
  root: ROOT_SETTING,
  role: {
    new: path(ROOT_SETTING, '/role/new'),
    list: path(ROOT_SETTING, '/role/list'),
    edit: (id) => path(ROOT_SETTING, `/role/${id}/edit`),
  },
  category: {
    new: path(ROOT_SETTING, '/category/new'),
    list: path(ROOT_SETTING, '/category/list'),
    view: (id) => path(ROOT_SETTING, `/category/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/category/${id}/edit`),
  },
  type: {
    new: path(ROOT_SETTING, '/type/new'),
    list: path(ROOT_SETTING, '/type/list'),
    view: (id) => path(ROOT_SETTING, `/type/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/type/${id}/edit`),
  },
};

export const PATH_SECURITY = {
  root: ROOT_SECURITY,
  permissionDenied: path(ROOT_SECURITY, '/permission-denied'),
  // ------------------------ SECURITY USERS ----------------------------------------
  user: {
    new: path(ROOT_SECURITY, `/user/new/`),
    view: (id) => path(ROOT_SECURITY, `/user/${id}/view`),
    edit: (id) => path(ROOT_SECURITY, `/user/${id}/edit`),
  },
};
