import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const initialState = {
  intial: false,
  isLoading: false,
  isFileLoading: false,
  success: false,
  error: null,
  responseMessage: null,
  orders: [],
  order: {},
  statusOptions: [
    { label: 'Pending', value: 'pending', color:'#1976d2', chipColor:'default', icon:'mdi:progress-tag'},
    { label: 'In Progress', value: 'in-progress', color:'#1976d2', chipColor:'primary', icon:'mdi:progress-alert'},
    { label: 'Invoiced', value: 'invoiced', color:'#008000', chipColor:'info', icon:'mdi:progress-check'},
    { label: 'Shipping', value: 'shipping', color:'#008000', chipColor:'info', icon:'mdi:truck-shipping'},
    { label: 'Completed', value: 'completed', color:'#008000', chipColor:'success', icon:'mdi:check-circle-outline'},
    { label: 'Canceled', value: 'canceled', color:'#FF0000', chipColor:'error', icon:'mdi:progress-close'},
  ],
  orderStatusDialog:false,
  captureImageDialog:false,
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null;
      state.isLoading = true;
    },

    // GET Orders
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.orders = action.payload;
      state.initial = true;
    },

    // GET Order
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.order = action.payload;
      state.initial = true;
    },

    fileLoading(state, action) {
      state.error = null;
      state.isFileLoading = action.payload;
    },
    
    // RESET SITE
    resetOrder(state){
      state.order = null;
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // RESET SITES
    resetOrders(state){
      state.orders = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },

    // Set orderStatusDialog
    setOrderStatusDialog(state, action) {
      state.orderStatusDialog = action.payload;
    },

    
    // Set orderStatusDialog
    setCaptureImageDialog(state, action) {
      state.captureImageDialog = action.payload;
    },

    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setResponseMessage,
  resetOrder,
  resetOrders,
  setFilterBy,
  setOrderStatusDialog,
  setCaptureImageDialog,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;

// ----------------------------------------------------------------------

export function addOrder(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`${CONFIG.SERVER_URL}orders/`, params);
        dispatch(slice.actions.getOrderSuccess(response.data))
        return response.data; 
      } catch (error) {
        console.error(error?.message);
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      }
  };
}

// ----------------------------------------------------------------------

export function updateOrder(params, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
      try {
        const response = await axios.patch(`${CONFIG.SERVER_URL}orders/update/${id}`, params);
        dispatch(slice.actions.getOrderSuccess(response.data)) 
      } catch (error) {
        console.error(error?.message);
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      }
  };
}

export function getOrders(customerID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        customer:customerID,
        // orderBy : {
        //   createdAt: -1
        // }
      }
      
      const response = await axios.get(`${CONFIG.SERVER_URL}orders`, {params});
      dispatch(slice.actions.getOrdersSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Orders loaded successfully'));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getOrder(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}orders/${id}`);
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOrder(customerID, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        customer: customerID,
      };
      const response = await axios.patch(`${CONFIG.SERVER_URL}orders/delete/${id}`,data);
      dispatch(slice.actions.setResponseMessage(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

export function updateOrderStatus(params, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setOrderStatusDialog(false));
      try {
        const data = {
          status: params?.status?.value
        };
        const response = await axios.patch(`${CONFIG.SERVER_URL}orders/status/${id}/`, data);
        dispatch(slice.actions.getOrderSuccess(response.data)) 
      } catch (error) {
        console.error(error?.message);
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      }
  };
}

export function downloadFile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.fileLoading(true));
    const response = await axios.get(`${CONFIG.SERVER_URL}files/${id}/download/` );
    dispatch(slice.actions.fileLoading(false));
    return response;
  };
}

export function sendOrderEmail(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`${CONFIG.SERVER_URL}orders/sendOrderEmail/${id}`);
        dispatch(slice.actions.setResponseMessage(response.data)) 
      } catch (error) {
        console.error(error?.message);
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      }
  };
}