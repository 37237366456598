import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
// export const Authenticate = Loadable(lazy(() => import('../sections/auth/Authenticate')));

// ----------------------------------------------------------------

// MAIN
export const PermissionDeniedPage = Loadable( lazy(() => import('../pages/dashboard/PermissionDeniedPage')));
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const ErrorPage = Loadable(lazy(() => import('../pages/ErrorPage')));

// ----------------------------------------------------------------

// DASHBOARD
export const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));

// ----------------------------------------------------------------

// CUSTOMER
export const CustomerList = Loadable(lazy(() => import('../pages/customers/CustomerList')));
export const CustomerAdd = Loadable(lazy(() => import('../pages/customers/CustomerAddForm')));
export const CustomerEdit = Loadable(lazy(() => import('../pages/customers/CustomerEditForm')));
export const CustomerView = Loadable(lazy(() => import('../pages/customers/CustomerViewForm')));

// CUSTOMERS SITE LIST
export const SiteList = Loadable(lazy(() => import('../pages/customers/sites/SiteList')));

// CUSTOMERS ORDER LIST
export const OrderList = Loadable(lazy(() => import('../pages/customers/orders/OrderList')));
export const OrderAdd = Loadable(lazy(() => import('../pages/customers/orders/OrderAddForm')));
export const OrderEdit = Loadable(lazy(() => import('../pages/customers/orders/OrderEdit')));
export const OrderView = Loadable(lazy(() => import('../pages/customers/orders/OrderViewForm')));

// SETTINGS
export const Setting = Loadable(lazy(() => import('../pages/settings/Setting')));


// SECURITY USERS
export const UserList = Loadable(lazy(() => import('../pages/security/users/UserList')));
export const UserAdd = Loadable(lazy(() => import('../pages/security/users/UserAddForm')));
export const UserView = Loadable(lazy(() => import('../pages/security/users/UserViewForm')));
export const UserEdit = Loadable(lazy(() => import('../pages/security/users/UserEditForm')));

// SECURITY SETTIGS ROLES
export const RoleList = Loadable(lazy(() => import('../pages/settings/securitySettings/role/RoleList')));
export const RoleAdd = Loadable(lazy(() => import('../pages/settings/securitySettings/role/RoleAddForm')));
export const RoleEdit = Loadable(lazy(() => import('../pages/settings/securitySettings/role/RoleEditForm')));

// ITEM CATEGORIES
export const CategoryList = Loadable(lazy(() => import('../pages/settings/categories/CategoryList')));
export const CategoryAdd = Loadable(lazy(() => import('../pages/settings/categories/CategoryAddForm')));
export const CategoryEdit = Loadable(lazy(() => import('../pages/settings/categories/CategoryEditForm')));
export const CategoryView = Loadable(lazy(() => import('../pages/settings/categories/CategoryViewForm')));

// ITEMS
export const TypeList = Loadable(lazy(() => import('../pages/settings/types/TypeList')));
export const TypeAdd = Loadable(lazy(() => import('../pages/settings/types/TypeAddForm')));
export const TypeEdit = Loadable(lazy(() => import('../pages/settings/types/TypeEditForm')));
export const TypeView = Loadable(lazy(() => import('../pages/settings/types/TypeViewForm')));

// ----------------------------------------------------------------
