import { useEffect, useState } from 'react';
import { PATH_CUSTOMERS, PATH_DASHBOARD, PATH_ORDERS, PATH_PROFILE, PATH_SECURITY, PATH_SETTING } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import { useAuthContext } from '../../../auth/useAuthContext';

// ----------------------------------------------------------------------

function NavigationConfig() {

  const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  );
  
  const ICONS = {
    blog: icon('ic_blog'),
    cart: icon('ic_cart'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    asset: icon('ic_ecommerce'),
    dashboard: icon('ic_dashboard'),
    chart: icon('ic_analytics'),
    setting: icon('ic_setting'),
    users: icon('ic_users'),
  };

  const { user, isSuperAdmin, isManager, isOperator, isCustomer  } = useAuthContext();
  const customerId = localStorage.getItem('customerId');

  const [navConfig, setConfig] = useState([
    {
      subheader: 'general',
      items: [
        // { title: 'Dashboard', path: PATH_DASHBOARD.root, icon: ICONS.dashboard },
      ],
    },
  ]);

  useEffect(() => {
    const updatedConfig = [...navConfig];
    

    if (!(isSuperAdmin || isManager || isOperator) && isCustomer) {
      updatedConfig[0].items.push({ title: 'Profile', path: PATH_PROFILE.view(customerId || user?.customer), icon: ICONS.user });
    }
    
    if (isSuperAdmin || isManager || isOperator) {
      updatedConfig[0].items.push({ title: 'Dashboard', path: PATH_DASHBOARD.root, icon: ICONS.dashboard });
      updatedConfig[0].items.push({ title: 'Customers', path: PATH_CUSTOMERS.root, icon: ICONS.ecommerce });
    }

    updatedConfig[0].items.push({ title: 'Orders', path: PATH_ORDERS.root, icon: ICONS.cart });

    if (isSuperAdmin || isManager) {
        updatedConfig[0].items.push({ title: 'Users', path: PATH_SECURITY.root, icon: ICONS.user });
        updatedConfig[0].items.push({ title: 'Settings', path: PATH_SETTING.root, icon: ICONS.setting });
    }
    
   
    
    setConfig(updatedConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [  ]);

  return navConfig;
};

export default NavigationConfig;
